import { ParticipantsSectionItemData, PersonalDetails } from '@/shared/types';

export const getParticipantData = (
  participant?: PersonalDetails
): ParticipantsSectionItemData => {
  return {
    fullName: `${participant?.firstName} ${participant?.lastName}`,
    email: participant?.email || '',
  };
};
