import { AnchorHashEnum } from '@/shared/types/common/common.types';

export const focusByRouteHash = (
  routeHash: string,
  focusableHashes: string[],
  item: string
) => {
  if (focusableHashes.includes(routeHash as AnchorHashEnum)) {
    const elementToSetFocusOn = document.querySelector(item);

    return (elementToSetFocusOn as HTMLInputElement | null)?.focus();
  }
};
