import { BasketPaymentMethod } from '~/composables/ecommerce/useEcommerce.types';
import { BookingPaymentMethod } from '~/ols-pages/course-booking/review-payment/reviewPayment.types';

export const mapBookingToBasketPaymentMethods = (
  bookingPaymentMethod: BookingPaymentMethod
): BasketPaymentMethod => {
  let paymentMethod: BasketPaymentMethod;

  switch (bookingPaymentMethod) {
    case BookingPaymentMethod.PAY_BY_QUOTE:
      paymentMethod = BasketPaymentMethod.PAY_BY_QUOTE;
      break;
    case BookingPaymentMethod.INVOICE:
      paymentMethod = BasketPaymentMethod.INVOICE;
      break;
    default:
      paymentMethod = BasketPaymentMethod.CARD;
  }

  return paymentMethod;
};

export const mapBasketToBookingPaymentMethods = (
  basketPaymentMethod: BasketPaymentMethod | undefined
): BookingPaymentMethod => {
  let paymentMethod: BookingPaymentMethod;

  switch (basketPaymentMethod) {
    case BasketPaymentMethod.PAY_BY_QUOTE:
      paymentMethod = BookingPaymentMethod.PAY_BY_QUOTE;
      break;
    case BasketPaymentMethod.PAYPAL:
      paymentMethod = BookingPaymentMethod.PAYPAL;
      break;
    case BasketPaymentMethod.INVOICE:
      paymentMethod = BookingPaymentMethod.INVOICE;
      break;
    default:
      paymentMethod = BookingPaymentMethod.CARD;
  }

  return paymentMethod;
};
