import {
  documentToHtmlString,
  Options,
} from '@contentful/rich-text-html-renderer';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import {
  Document,
  INLINES,
  NodeData,
  BLOCKS,
} from '@contentful/rich-text-types';
import { ContentMark, ContentModel } from './richText.types';

export const isLocalURI = (uri: string): boolean => {
  // in contentful only prod url might appear,
  // therefore make sense to check against it on all envs
  const prodURI = 'https://advanceonline.cam.ac.uk';
  return (
    uri.startsWith(prodURI) ||
    uri.startsWith('/') ||
    uri.startsWith('mailto') ||
    uri.startsWith('tel')
  );
};

const wrapProtocolRelativeUrl = (url: string) => `https:${url}`;

const hasNodeTypeGivenMark = (marks: ContentMark[], type: string): boolean => {
  return marks.some((mark) => mark.type === type);
};

const renderMarks = (marks: ContentMark[], content: string) => {
  let taggedContent = content;

  if (hasNodeTypeGivenMark(marks, 'bold')) {
    taggedContent = `<b>${taggedContent}</b>`;
  }

  if (hasNodeTypeGivenMark(marks, 'italic')) {
    taggedContent = `<i>${taggedContent}</i>`;
  }

  if (hasNodeTypeGivenMark(marks, 'underline')) {
    taggedContent = `<u>${taggedContent}</u>`;
  }

  if (hasNodeTypeGivenMark(marks, 'subscript')) {
    taggedContent = `<sub>${taggedContent}</sub>`;
  }

  if (hasNodeTypeGivenMark(marks, 'superscript')) {
    taggedContent = `<sup>${taggedContent}</sup>`;
  }

  return taggedContent;
};

const renderHyperlink = (node: NodeData): string => {
  const { uri } = node.data;
  const { value, marks } = node.content[0];
  const target = isLocalURI(uri) ? '_self' : '_blank';
  const readerLinkMessage =
    target === '_blank'
      ? '<span class="sr-only">(Opens in a new window)</span>'
      : '';
  const link = `<a href="${uri}" target="${target}">${value}${readerLinkMessage}</a>`;

  return renderMarks(marks, link);
};

const renderRowMarks = (rowContent: NodeData): string => {
  if (rowContent.nodeType === 'hyperlink') {
    return renderHyperlink(rowContent);
  }
  if (rowContent.marks) {
    return renderMarks(rowContent.marks, rowContent.value);
  }
  return `${rowContent}`;
};

const renderRows = (node: NodeData): string => {
  const flattenedTable = node.content.map((item: NodeData) =>
    item.content.map((nestedItem: NodeData) =>
      nestedItem.content.length > 1
        ? nestedItem.content.reduce(
            (prev: NodeData, next: NodeData) =>
              renderRowMarks(prev) + renderRowMarks(next)
          )
        : renderRowMarks(nestedItem.content[0])
    )
  );
  const divElements = flattenedTable
    .map((innerArray: string[]) =>
      innerArray
        .map((value: string) => `<div class="mb-10px">${value}</div>`)
        .join('')
    )
    .map(
      (innerDivs: string) => `<div class="table-cell p-10px">${innerDivs}</div>`
    )
    .join('');

  const rowContent =
    node.nodeType === BLOCKS.TABLE_ROW
      ? divElements
      : node.content[0].content[0].value;

  return `<div class="table-row even:bg-page-grey">${rowContent}</div>`;
};

const renderEmbeddedAssets = (node: NodeData): string => {
  const { fields } = node.data.target;

  const fileUrl = fields?.file?.url;

  if (!fileUrl) return '';

  const altText = fields.description || '';
  const url = wrapProtocolRelativeUrl(fileUrl);

  return `<img src="${url}" alt="${altText}" class="w-full pb-20px md:pb-40px md:last:pb-28px"/>`;
};

const renderAccreditation = (node: NodeData): string => {
  const { fields } = node.data.target;

  const logoUrl = fields?.accreditationLogo?.fields?.file?.url;

  if (!logoUrl) return '';

  const altText = fields.logoDescription || '';

  const url = wrapProtocolRelativeUrl(logoUrl);

  const text = fields.descriptionNew
    ? renderRichTextDocumentToHtml(fields.descriptionNew)
    : '';

  return (
    '<div class="inline-flex flex-col justify-between max-w-full max-w-264px md:max-w-390px items-center gap-2 flex-1 mb-5 py-5 accreditation-odd-mr-5 accreditation-first-two-figure">' +
    `<div class="flex flex-col justify-center items-center flex-shrink-0 mx-auto w-264px md:w-390px px-32px md:px-95px">` +
    `<img src="${url}" class="object-contain w-200px h-92.025px" alt="${altText}"/>` +
    `</div>` +
    (text
      ? `<div class="text-base text-center text-sm text-grey-500 w-264px md:w-300px mx-auto">${text}</div>`
      : '') +
    '</div>'
  );
};

const renderEmbeddedEntry = (node: NodeData): string => {
  const { sys } = node.data.target;

  if (sys?.contentType?.sys?.id === ContentModel.ACCREDITATION) {
    return renderAccreditation(node);
  }

  return '';
};

const renderConfig: Partial<Options> = {
  renderNode: {
    [BLOCKS.TABLE_ROW]: (node) => renderRows(node),
    [INLINES.HYPERLINK]: (node: NodeData): string => renderHyperlink(node),
    [BLOCKS.EMBEDDED_ASSET]: (node: NodeData): string =>
      renderEmbeddedAssets(node),
    [BLOCKS.EMBEDDED_ENTRY]: (node: NodeData): string =>
      renderEmbeddedEntry(node),
  },
};

export const renderRichTextDocumentToHtml = (
  richTextDocument: Document
): string => {
  if (!richTextDocument) {
    return '';
  }

  return documentToHtmlString(richTextDocument, renderConfig)
    .replace(/\n/g, '<br/>')
    .replace(/\u00A0/g, ' ');
};

export const renderRichTextDocumentToPlainText = (
  richTextDocument: Document
): string => {
  if (!richTextDocument) {
    return '';
  }

  return documentToPlainTextString(richTextDocument, '<br/>');
};

export const isRichTextDocumentEmpty = (
  richTextDocument: Document
): boolean => {
  return !renderRichTextDocumentToHtml(richTextDocument)
    .replace(/(<([^>]+)>)/gi, '')
    .trim();
};
