import { BillingAddress } from '../common/common.types';

export enum EcommerceBookingStatusEnum {
  BOOKING_INITIALIZED = 'BOOKING_INITIALIZED',
  BILLING_ADDRESS_ASSIGNED = 'BILLING_ADDRESS_ASSIGNED',
  BILLING_ADDRESS_ASSIGNMENT_FAILURE = 'BILLING_ADDRESS_ASSIGNMENT_FAILURE',
  BOOKING_REVIEW = 'BOOKING_REVIEW',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_CREATION_FAILURE = 'ORDER_CREATION_FAILURE',
  ORDER_PAID = 'ORDER_PAID',
  QUOTE_GENERATED = 'QUOTE_GENERATED',
  ORDER_PAYMENT_FAILURE = 'ORDER_PAYMENT_FAILURE',
  SESSION_RECREATED = 'SESSION_RECREATED',
  REQUEST_INVOICE_RECEIVED = 'REQUEST_INVOICE_RECEIVED',
}

export enum EcommercePaymentRedirectStatusEnum {
  ERROR = 'error',
  CANCELLED = 'cancelled',
  VALIDATION_ERROR = 'validation-error',
}

export type EcommerceBasketProduct = {
  sku: string;
  quantity: number;
};

export type EcommerceBillingInformation = BillingAddress & {
  id: number;
  firstName: string;
  lastName: string;
};

export type EcommerceCourseDetails = {
  title: string;
  startDate: string;
  endDate: string;
  isAsync: boolean;
};

export type EcommercePaymentRedirectInformation = {
  storeId: string;
  sessionId: string;
  orderId: number;
};

export type EcommercePaymentRedirect = {
  uri: string;
  payload: EcommercePaymentRedirectInformation;
};

export enum PaypalSDKIntent {
  CAPTURE = 'capture',
  ORDER = 'order',
}
