
import {
  defineComponent,
  PropType,
  computed,
  watch,
  ref,
} from '@nuxtjs/composition-api';
import { Document as ContentfulDocument } from '@contentful/rich-text-types';
import { renderRichTextDocumentToHtml } from '../contentful/rich-text/richText.utils';
import Collapsible from './Collapsible.vue';

type CallbackOnEnabled = () => void;

export default defineComponent({
  components: {
    Collapsible,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    expanderContent: {
      type: Object as PropType<ContentfulDocument>,
      required: true,
    },
    expanderTitle: {
      type: String,
      required: true,
    },
    isCheckBulletStyle: {
      type: Boolean,
      default: false,
    },
    onEnabled: {
      type: Function as PropType<CallbackOnEnabled>,
      default: () => {},
    },
    expandedByDefault: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props) => {
    const isCollapsibleExpanded = ref(false);

    const toggleExpanded = (id: string, isExpanded: boolean) => {
      isCollapsibleExpanded.value = isExpanded;
    };

    const expanderContentPlainText = computed(() =>
      renderRichTextDocumentToHtml(props.expanderContent)
    );

    watch(
      () => props.expandedByDefault,
      () => {
        isCollapsibleExpanded.value = props.expandedByDefault;
      }
    );

    return {
      toggleExpanded,
      expanderContentPlainText,
      isCollapsibleExpanded,
    };
  },
});
