export type ContentMark = {
  type: string;
};

export enum ContentModel {
  ACCREDITATION = 'accreditation',
  QUOTATION = 'quotation',
  VIDEO_URL = 'videoUrl',
  CALL_TO_ACTION = 'callToAction',
  B2B_TESTIMONIALS = 'b2bTestimonials',
  SIDE_BY_SIDE_NO_OUTLINE = 'sideBySideNoOutline',
  EXPANDER_ITEM = 'faqItem',
  CUSTOMER_LOGOS = 'customerLogos',
}

export enum ImagePosition {
  LEFT = 'Left-side',
  RIGHT = 'Right-side',
}
