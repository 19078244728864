type Discount = {
  discountValue: number;
  totalDiscountedPrice: number;
};

export type Currency = {
  code: string;
  name: string;
  symbolLeft: string;
  symbolRight: string;
};

export type PersonalDetails = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  isOrganisationCoveringCost?: boolean;
  jobTitle?: string;
  companyName?: string;
};

export type LearnerDetails = {
  firstName: string;
  lastName: string;
  email: string;
};

export type ProductCost = {
  quantity: number;
  tax: number;
  subTotal: number;
  totalIncludingTax: number;
  unitPrice: number;
  currency: Currency;
  discount?: Discount;
};

export type BillingAddress = {
  streetAddress1: string;
  streetAddress2?: string;
  streetAddress3?: string;
  companyName?: string;
  city: string;
  postcode: string;
  countryName: string;
  region?: string;
};

export type ErrorResponse = {
  status: number;
};

export type RequestError = {
  response: ErrorResponse;
};

export enum AnchorHashEnum {
  BROCHURE_DOWNLOAD_FORM = '#course-brochure',
  FEATURED_COURSES_SECTION = '#featured-courses-section',
  COURSE_DETAILS = '#course-details',
  PERSONAL_DETAILS_MAIN_CONTACT = '#main-contact',
  PERSONAL_DETAILS_PARTICIPANTS = '#participants',
  PERSONAL_DETAILS_NUMBER_OF_PARTICIPANTS = '#number-of-participants',
}

export enum KeyboardEventKey {
  TAB = 'Tab',
  ESC = 'Escape',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  SHIFT = 'Shift',
}

export type ParticipantsSectionItemData = {
  fullName: string;
  email: string;
  companyName?: string;
};

export enum CustomerServiceContactInfo {
  UOC_ONLINE_INFO_EMAIL = 'uoc.online.info@cambridge.org',
  REASONABLE_ADJUSTMENTS_EMAIL = 'uoc.online@cambridge.org',
  EMAIL_ADDRESS = 'uoc.online.customerservice@cambridge.org',
  UK_TEL_NUMBER = '+44 1223 609985',
  US_TEL_NUMBER = '+1 332-233-8085',
}
